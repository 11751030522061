<template>
	<div class="order-create__section">

		<div class="order-create__row custom-row">
			<div class="order-create__col custom-col">
        <div class="order-create__table block-table">
          <div class="block-table__row">
            <div class="block-table__col block-table__col--20">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'apcDelcon_sendDate',
              ])"></div>
                {{$t('apcDelcon_sendDate.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="MR.data.sendDate"
                    :date="true"
                    :dateType="'DD MMM, YYYY'"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'returnGoods_shipped',
              ])"></div>
                {{$t('returnGoods_shipped.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="MR.data.shippedDate"
                    :date="true"
                    :dateType="'DD MMM, YYYY'"
                />
              </div>
            </div>
<!--            <div class="block-table__col block-table__col&#45;&#45;20">-->
<!--              <div class="block-table__label"-->
<!--                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate([-->
<!--                'consolidations_customerName',-->
<!--              ])"></div>-->
<!--                {{$t('consolidations_customerName.localization_value.value')}}-->
<!--              </div>-->
<!--              <div class="block-table__content">-->
<!--                <ValueHelper-->
<!--                    :value="MR.data.customerName"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
            <div class="block-table__col block-table__col--20">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'returnGoods_trackingNumber',
              ])"></div>
                {{$t('returnGoods_trackingNumber.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="MR.data.trackingNumber"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20">
              <div class="block-table__label">
                {{$t('forbiddenGoods_Store.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <RadioDefault
                    v-if="MR.data.Order.data.warehouse"
                    class="white-space-line width-initial mr-3"
                    :label="MR.data.Order.data.warehouse.name"
                    :name="'warehouse'"
                    :value="true"
                    :disabled="true"
                />
              </div>
            </div>
          </div>

          <div class="order-create__row custom-row"
               v-if="MR.data.productsForCellArray.length > 0 && _.has(_.first(MR.data.productsForCellArray).data.Product.product.productObject, 'id')"
          >
            <div class="order-create__col custom-col">

              <div class="block-table__name block-table__name--mobile  mb-4"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_ProductWarehouse'])"></div>
                {{$t('fbm_ProductWarehouse.localization_value.value')}}
              </div>

              <div class="site-table-wrap table-small"
                   v-if="!isMobileFunc()"
              >
                <table class="site-table">
                  <thead>
                  <tr>
                    <th>SKU</th>
                    <th class="text-lg-right">{{$t('fbm_ItemsQty.localization_value.value')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(item, index) in MR.data.productsForCellArray"
                      :key="index"
                  >
                    <td>
                      {{item.data.Product.product.productObject.name_for_fbm}}
                    </td>
                    <td align="right">{{item.data.Product.product.count}}</td>
                  </tr>
                  </tbody>
                </table>

              </div>

              <div class="site-table-mobile"
                   v-if="isMobileFunc()"
              >
                <div class="site-table-mobile__inner custom-row">
                  <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                       v-for="(item, index) in MR.data.productsForCellArray"
                       :key="index">

                    <div class="confirm-product-card">
                      <div class="confirm-product-card__name">
                        <ValueHelper
                            :value="item.data.Product"
                            :deep="'product.productObject.name_for_fba'"
                        />
                      </div>

                      <div class="confirm-product-card__items">
                        <div class="confirm-product-card__item">
                          <div class="confirm-product-card__item-name"
                               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate(['fbm_ItemsQty'])"></div>
                            {{$t('fbm_ItemsQty.localization_value.value')}}
                          </div>
                          <div class="confirm-product-card__item-val">
                            {{item.data.Product.product.count}}
                          </div>
                        </div>
                      </div>
                    </div>

<!--                    <div class="site-table-mobile__item">-->
<!--                      <div class="site-table-mobile__head">-->
<!--                        {{item.data.Product.product.productObject.name_for_fbm}}-->
<!--                      </div>-->
<!--                      <div class="site-table-mobile__row custom-row">-->
<!--                        <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;33">-->
<!--                          Q-ty-->
<!--                          <span>-->
<!--											{{item.data.Product.product.count}}-->
<!--										</span>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="block-table__row">

            <div class="block-table__col block-table__col--20">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'fbm_DimentionsWeightLB',
              ])"></div>
                {{$t('fbm_DimentionsWeightLB.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="MR.data.Dimensions.data.weightLB"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'fbm_DimentionsWeightOZ',
              ])"></div>
                {{$t('fbm_DimentionsWeightOZ.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="MR.data.Dimensions.data.weightOZ"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'returnGoods_price',
              ])"></div>
                {{$t('returnGoods_price.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="MR.data.price"
                />
              </div>
            </div>
            <div class="block-table__col block-table__col--20">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'returnGoods_status',
              ])"></div>
                {{ $t('returnGoods_status.localization_value.value') }}
              </div>
              <div class="block-table__content" v-if="MR.data.Order.data.status">
                {{ $t(`${MR.data.Order.data.status.translation}.localization_value.value`) }}
              </div>
            </div>

          </div>

          <div class="block-table__row">
            <div class="block-table__col">
              <div class="block-table__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                'returnGoods_comment',
              ])"></div>
                {{$t('returnGoods_comment.localization_value.value')}}
              </div>
              <div class="block-table__content">
                <ValueHelper
                    :value="MR.data.comment"
                />
              </div>
            </div>
          </div>

        </div>
			</div>
		</div>
	</div>
</template>

<script>


  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
  import {mixinDetictingMobile} from "@/mixins/mobileFunctions";
  import {MEEST_STATUSES_ARRAY} from "@/staticData/staticVariables";
  export default {
		name: "ConfirmationMeest",

		components: {
      RadioDefault,
      ValueHelper
		},

    mixins: [mixinDetictingMobile],

		props: {
      MR: Object,
		},

		data() {
			return {
        MEEST_STATUSES_ARRAY: MEEST_STATUSES_ARRAY,
			}
		},

		methods: {

		}
	}
</script>

<style scoped>

</style>
