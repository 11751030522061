<template>
  <MeestReturnsShowSection
          :MR="MR"
          :createData="createData"
          @save="save"
  />
</template>

<script>
  import {MeestReturns} from "../../models/MeestReturns";
  import MeestReturnsShowSection from "./MeestReturnsShowSection/MeestReturnsShowSection";

  export default {
    name: "MeestReturnsShow",

    components:{
      MeestReturnsShowSection,
    },

    data() {
      return {
        MR: new MeestReturns(),
        transactionUser: {},
        createData: null,
      }
    },

    mounted() {
      this.initMeestReturnsEdit()
    },

    methods: {


      initMeestReturnsEdit() {

        this.MR.data.Order.setId(this.$route.params.id)

        this.$store.dispatch('getReturnGoodsDocuments', this.MR.data.Order.getId()).then((response) => {
          let item = this.getRespData(response)
          this.MR.setItem(item)
        }).catch(error => this.createErrorLog(error))
      },

      save(edit = false) {
        // if (!this.MR.firstValidation()) return

        let data = this.MR.prepareSave()

        let typeDispatch = 'createReturnGoodsDocuments'

        if (edit) {
          typeDispatch = 'updateReturnGoodsDocuments'
          data = {
            id: this.MR.data.Order.getId(),
            data: data,
          }
        }

        this.$store.dispatch(typeDispatch, data).then(response => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$router.push(this.$store.getters.GET_PATHS.ordersMeestReturns)
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

    }

  }
</script>

<style scoped>

</style>
